@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Space Grotesk";
  }
}

#root {
  @apply min-h-screen w-full flex flex-col items-center bg-gray-1;
}
